import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="bio">
<h1>Antonio Gallenga</h1>
<p>Antonio Carlo Napoleone Gallenga nasce a Parma il 4 novembre 1810. È figlio di Marianna Lombardini, donna parmigiana, e Celso Gallenga, soldato napoleonico di origine piemontese (di Castellamonte, nella regione del Canavese).</p>
<ImgSinistra nome="gallenghino.PNG" alt="Gallenga" didascalia="Immagine del Gallenga tratta da una sua fotografia" />
<p>Trascorre l’infanzia e l’adolescenza a Parma, dove studia lettere e medicina, ma, in seguito ai moti del 1830-1831, è costretto a fuggire dalla città emiliana. Lavora per alcuni anni come precettore e insegnante privato, prima in Corsica e poi in Marocco, fino a quando decide di trasferirsi negli Stati Uniti (1836).</p>
<p>A Boston entra in contatto con diversi intellettuali e inizia a lavorare come docente di letteratura, giornalista e conferenziere. Nonostante la promettente carriera, nel 1839 lascia l’America e si trasferisce a Londra, dove, grazie al personaggi influenti, quali Lady Sidney Morgan, trova impiego  come traduttore e critico letterario. A questo periodo risalgono le sue prime opere, pubblicate con lo pseudonimo di Luigi Mariotti.</p>
<ImgSinistra nome="foto.png" alt="Gallenga" didascalia="Unica fotografia del Gallenga" />
<p>Essendo vicino agli ambienti mazziniani e alla “Giovane Italia”, si prodiga per diffondere con la sua scrittura la causa nazionale italiana, sia attraverso saggi (Italy, General View of its History and Literature, 1841) che attraverso opere narrative (The Blackgown Papers, 1846).</p>
<p>Dopo essersi convertito alla causa monarchica, lavora come diplomatico per il governo sabaudo e, nel 1854, viene eletto come deputato alla Camera subalpina di Torino, ruolo da cui due anni più tardi è costretto a dimettersi a causa di uno scandalo. Nel frattempo prosegue la carriera accademica a Londra, dove insegna per dieci anni (1849-1859) all’University College e dove pubblica diverse opere sulla lingua e la cultura italiana (Mariotti’s Italian Grammar, 1854; Historical Memoir of Fra Dolcino and his Times, 1853; History of Piedmont, 1855-56).</p>
<p>Alla fine degli anni ‘50 viene assunto dalle rivista The Times in qualità di corrispondente estero e di inviato; svolgerà questa professione fino al 1884, e scriverà articoli di attualità e reportage di viaggio dai Paesi più disparati del mondo: Stati Uniti (1863), Danimarca (1864), Spagna (1865-1866, 1868-1869), Cuba (1873), Istanbul (1875), America del Sud (1879-1880),  Russia (1882). Durante questo periodo non cessa però di osservare e di scrivere della realtà storica e sociale italiana (Italy Revisited, 1877; The Pope and the King, 1879) e di riflettere sulla propria avventurosa esistenza (Autobiographical Sketch Illustrative of Italian Life during the Insurrection of 1831, 1854; Episodes of my Second Life, 1885).</p>
<p>Negli ultimi anni, dopo aver persino sperimentato la forma narrativa del romanzo (Jenny Jennet, 1886), collabora con La Nazione di Firenze a un serie di articoli che descrivono la nazione e il popolo che lo hanno ospitato: questi articoli saranno poi raccolti nel saggio Vita inglese (1890).</p>
<p>Antonio Gallenga si spegne a LLandogo (Galles), nella casa dove si è ritirato con la seconda moglie, il 17 dicembre 1895.</p>
<ImgCentro nome="gallenghetto.PNG" alt="Gallenga"/>

	</PaginaTesto>
}